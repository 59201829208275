import React from "react";
import { Link } from "gatsby";

import agroImg from "@assets/images/agro.png";
import saudeImg from "@assets/images/saude.png";
import industriaComercioImg from "@assets/images/industria_comercio.png";

const Areas = () => {
  return (
    <div className="columns slider-areas">
      <div className="column">
        <Link to="/agro">
          <img src={agroImg} alt="Agro" />
        </Link>
      </div>

      <div className="column">
        <Link to="/saude">
          <img src={saudeImg} alt="Saúde" />
        </Link>
      </div>

      <div className="column">
        <Link to="/industria-comercio">
          <img src={industriaComercioImg} alt="Indústria e comércio" />
        </Link>
      </div>
    </div>
  );
};

export default Areas;
