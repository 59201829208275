import React from "react";

import Contact from "components/Contact";
import Container from "components/Container";

const storKey = "cookiesAccept";

const Footer = () => {
    if (typeof window !== "undefined") {
        if (localStorage.getItem(storKey) != "true") {
            document.documentElement.classList.add("cookies-not-accept");
        }
    }

    const onClick = () => {
        document.documentElement.classList.remove("cookies-not-accept");
        localStorage.setItem(storKey, "true");
    };
    return (
        <footer>
            <Contact />
            <div className="disclaimer-cookies">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-11">
                A <b>Sanches Lopes, Sales &amp; Advogados </b> utiliza cookies e outras tecnologias neste
                website de acordo com a LGPD (Lei 13.709/18).

    
                    Ao continuar navegando você concorda com nossos <a href="/lgpd">TERMOS DE USO</a> e <a href="/privacidade">POLÍTICA DE PRIVACIDADE</a>.

                        </div>
                        <div className="column is-1">
                            <button className="button is-danger" id="cookiesAccept" onClick={onClick}>OK, ENTENDI!</button>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
