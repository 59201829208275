import React from "react";

import Container from "./Container";

const Contact = () => {
    return (
        <div className="contact">
            <Container id="contato">
                <h1 className="title has-text-white text-center">Contato</h1>
                <div className="columns">
                    {/* <!-- Start Google Map --> */}
                    <div className="column text-center">
                        <iframe
                            src="https://maps.google.com/maps?width=100%&amp;height=500&amp;hl=pt-br&amp;coord=-14.618935, -57.491420&amp;q=Escrit%C3%B3rio%20de%20Advocacia%20SLS%20Advogados%20Associados+(SLS%20Advogados)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                            width="100%"
                            height="347"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                        <h2>Tangará da Serra - MT</h2>
                    </div>
{/*
                    <div className="column text-center">

                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2751.7558188050834!2d-60.136682476802406!3d-12.741424990746884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDQ0JzI5LjgiUyA2MMKwMDgnMTIuMSJX!5e0!3m2!1spt-BR!2sbr!4v1631044209244!5m2!1spt-BR!2sbr"
                            width="100%"
                            height="347"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                        <h2>Vilhena - RO</h2>
                    </div>*/}
                    {/* <!-- End Google Map --> */}

                    {/* <!-- Start  contact --> */}
                    <div className="column">
                        <div className="form contact-form">
                            <form
                                action=""
                                method="post"
                                role="form"
                                className="contactForm"
                                netlify-honeypot="bot-field"
                                data-netlify="true"
                            >
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" name="name" placeholder="Nome" required />
                                    </div>
                                </div>
                                {/* Email */}
                                <div className="field">
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="mail"
                                            name="mail"
                                            placeholder="E-mail"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Phone */}
                                <div className="field">
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="tel"
                                            name="phone"
                                            placeholder="Telefone"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Mensagem */}
                                <div className="field">
                                    <div className="control">
                                        <textarea className="textarea" placeholder="Mensagem"></textarea>
                                    </div>
                                </div>

                                {/* Enviar */}
                                <div className="field">
                                    <div className="control">
                                        <button type="submit" className="button is-medium is-fullwidth">
                      Enviar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <!-- End Left contact --> */}
                </div>
            </Container>
            <div className="text-center">
                <div className="container" style={{ fontSize: "1.0rem" }}>
                    <b><a href="/lgpd">Termos de uso</a> | <a href="/privacidade">Política de privacidade</a></b>
                </div>
                <p>
          &copy; Copyright <b>SLS & Advogados Associados {new Date().getFullYear()}</b>. Todos direitos
          reservados.
                </p>
                <p>AppCE - Aplicativos e CIA</p>
            </div>
        </div>
    );
};

export default Contact;
